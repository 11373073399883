import { useEffect, useState } from "react";
import { StyledButtonMain } from "../common/Buttons";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const Unsubscribe = () => {
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  // Get the firm id from the URL
  const { firmId } = useParams<{ firmId: string }>();
  const [firm, setFirm] = useState<any>({ email: "" });
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/firms/id?id=${firmId}`)
      .then((res) => {
        if (res.data) {
          setFirm(res.data.firm);
        }
      });
  }, [firmId]);

  const unsubscribeUser = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/user/unsubscribe`,
        { firm_id: firm._id },
        axiosConfig
      )
      .then((res) => {
        if (res.data) {
          window.location.reload();
        }
      });
  };
  return firm["_id"] ? (
    firm["do_not_contact"] ? (
      <h1>Email {firm["Email"]} unsubscribed</h1>
    ) : (
      <div>
        <h1>Unsubscribe from all the communication from Oakland Thrive?</h1>
        <br></br>
        <p>Firm Email: {firm["Email"]}</p>
        <br></br>
        <StyledButtonMain variant="contained" onClick={unsubscribeUser}>
          Unsubscribe
        </StyledButtonMain>
      </div>
    )
  ) : (
    <h1>No Firm Find</h1>
  );
};

export default Unsubscribe;
