import { Box, Switch, Table, TableBody, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import TaskCard from "./TaskCard";
import moment from "moment";
import B2BTaskCard from "./B2BTaskCard";

const decodeTimeLine = (timeline: any) => {
  switch (timeline) {
    case "30 days":
      return 30;
    case "60 days":
      return 60;
    case "90 days":
      return 90;
    default:
      return "On going";
  }
};

const Tasks = (props: any) => {
  const firms = props.firms;
  const [originalActions, setOriginalActions] = useState<any>(null); // [action1, action2, ...
  const [actions, setActions] = useState<any>(null);
  const [b2bCheck, setB2bCheck] = useState<boolean>(false);
  const actionPlanActivities = props.caseActivities
    ? props.caseActivities.filter((caseItem: any) => {
        return caseItem.activity_type === "Business Action Plan";
      })
    : [];
  const actionPlans: string[] = actionPlanActivities.map((activity: any) => {
    return activity.action_plan_id;
  });
  useEffect(() => {
    const fetchActionData = async () => {
      const token: string | undefined = Cookies.get("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
        },
      };
      if (actionPlans.length > 0) {
        let res: AxiosResponse = await axios.get(
          `${
            process.env.REACT_APP_API_DOMAIN
          }/actionPlan?action_plan_id=${actionPlans.join(",")}`,
          axiosConfig
        );
        const rawActions = res.data;
        // console.log("cases ", props.openCases);
        // console.log("actions", rawActions);
        if (rawActions) {
          if (rawActions.length > 0) {
            let tempActions: any[] = [];
            rawActions.map((actionItem: any) => {
              actionItem.content.map((actionDetail: any) => {
                actionDetail["case_id"] = actionItem["case_id"];
              });
              tempActions = tempActions.concat(actionItem.content);
            });

            tempActions.map((action: any) => {
              action["daysLeft"] =
                typeof decodeTimeLine(action.timeLine) === "number"
                  ? moment(action["startDate"])
                      .add(decodeTimeLine(action.timeLine), "days")
                      .diff(moment(), "days")
                  : 9999;
            });
            setActions(tempActions.sort((a, b) => a.daysLeft - b.daysLeft));
            setOriginalActions(
              tempActions.sort((a, b) => a.daysLeft - b.daysLeft)
            );
          } else if (rawActions.content.length > 0) {
            let tempActions: any[] = [];
            rawActions.content.map((actionDetail: any) => {
              actionDetail["case_id"] = rawActions["case_id"];
            });
            tempActions = tempActions.concat(rawActions.content);

            tempActions.map((action: any) => {
              action["daysLeft"] =
                typeof decodeTimeLine(action.timeLine) === "number"
                  ? moment(action["startDate"])
                      .add(decodeTimeLine(action.timeLine), "days")
                      .diff(moment(), "days")
                  : 9999;
            });
            setOriginalActions(rawActions.content);
            setActions(rawActions.content);
          }
        }
      }
    };
    fetchActionData();
  }, [props.caseActivities]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setB2bCheck(event.target.checked);
    if (event.target.checked) {
      setActions(
        originalActions.filter((action: any) => {
          return action.action === "B2B Referral";
        })
      );
    } else {
      setActions(originalActions);
    }
  };

  const itemTemplate = (action: any, index: any) => {
    if (!action.completion) {
      if (action.action === "B2B Referral") {
        return (
          <B2BTaskCard
            firm={firms.find((firm: any) => {
              return firm.openCase === action.case_id;
            })}
            key={index}
            action={action}
          ></B2BTaskCard>
        );
      } else {
        return (
          <TaskCard
            firm={firms.find((firm: any) => {
              return firm.openCase === action.case_id;
            })}
            key={index}
            action={action}
          ></TaskCard>
        );
      }
    }
    return null;
  };

  return actions ? (
    <>
      <Box
        sx={{
          width: "100%",
          height: "770px",
          backgroundColor: "#e7e7e747",
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "#9E1F63FF",
            padding: "3px",
            margin: 0,
            color: "white",
            borderRadius: "15px 15px 0 0",
          }}
        >
          <Typography sx={{ display: "inline-block" }} variant="h6">
            {b2bCheck ? "B2B Plan Calendar" : "Action Plan Calendar"}
          </Typography>
          <Switch
            checked={b2bCheck}
            color="warning"
            sx={{ float: "right" }}
            onChange={handleSwitchChange}
          ></Switch>
        </Box>

        {/* <VirtualScroller
          items={actions}
          itemSize={20}
          itemTemplate={(item, index) => itemTemplate(item, index)}
          className="border-1 surface-border border-round"
          style={{ height: "550px", width: "100%", backgroundColor: "white" }}
        /> */}

        <Box sx={{ overflowY: "auto", height: "92%", width: "100%" }}>
          {actions.map((action: any, index: number) => {
            if (!action.completion) {
              if (action.action === "B2B Referral") {
                return (
                  <B2BTaskCard
                    firm={firms.find((firm: any) => {
                      return firm.openCase === action.case_id;
                    })}
                    key={index}
                    action={action}
                  ></B2BTaskCard>
                );
              } else {
                return (
                  <TaskCard
                    firm={firms.find((firm: any) => {
                      return firm.openCase === action.case_id;
                    })}
                    key={index}
                    action={action}
                  ></TaskCard>
                );
              }
            }
            return null;
          })}
        </Box>
      </Box>
    </>
  ) : (
    <>No Action Plan</>
  );
};

export default Tasks;
