import React, { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import NavBar from "./NavBar";
import { Outlet } from "react-router-dom";
import { useNavbarContext } from "../../context/NavbarContext";
import FeedbackWidget from "../Feedback/FeedbackWidget";

const DashboardBFC = (props: any) => {
  const { isNavbarOpen, setIsNavbarOpen } = useNavbarContext();

  return (
    <div>
      {isNavbarOpen ? (
        <div className="grid grid-cols-5 gap-5">
          <div className="ml-3 col-span-1 col-start-1">
            <div className="grid grid-rows-[770px] bg-white rounded-lg shadow-md inset-0">
              <NavBar />
            </div>
          </div>

          <div className="col-start-2 col-span-4">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-5 gap-5">
          <div className="col-start-1 col-span-5">
            <Outlet />
          </div>
        </div>
      )}
      <FeedbackWidget />
    </div>
  );
};

export default DashboardBFC;
