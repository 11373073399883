// import React, { useState } from "react";
// import ThriveModal from "../common/ThriveModal";
// import FeedbackForm from "./FeedbackForm";

// const FeedbackWidget: React.FC = () => {
//   const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

//   const toggleFeedback = () => {
//     setIsFeedbackOpen(!isFeedbackOpen); // Toggle modal visibility
//   };

//   return (
//     <div>
//       {/* Feedback Button Positioned at Bottom Right */}
//       <div className="fixed bottom-4 right-4 rounded-lg shadow-lg max-w-md">
//         <button
//           onClick={toggleFeedback}
//           className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline hover:bg-blue-700"
//         >
//           Give Feedback
//         </button>
//       </div>

//       {/* Modal to show Feedback Form */}
//       <ThriveModal
//         open={isFeedbackOpen}
//         setOpen={setIsFeedbackOpen}
//         closeByClick={true}
//         contentComponent={
//           <div className="p-6 rounded-lg shadow-xl max-w-lg mx-auto">
//             <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
//               We Value Your Feedback
//             </h2>
//             <FeedbackForm />
//           </div>
//         }
//       ></ThriveModal>
//     </div>
//   );
// };

// export default FeedbackWidget;

import React, { useState } from "react";
import FeedbackForm from "./FeedbackForm";

// Custom Modal Component using Tailwind
const CustomModal = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  if (!open) return null;

  return (
    <>
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
        onClick={onClose} // Close modal on backdrop click
      ></div>

      <div className="fixed inset-0 flex items-center  justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-1/2 relative z-10">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-3xl"
          >
            &times;
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

const FeedbackWidget: React.FC = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const toggleFeedback = () => {
    setIsFeedbackOpen(!isFeedbackOpen); // Toggle modal visibility
  };

  return (
    <div>
      {/* Feedback Button Positioned at Bottom Right */}
      <div className="fixed bottom-4 right-4 rounded-full shadow-lg max-w-md">
        <button
          onClick={toggleFeedback}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline hover:bg-blue-700"
        >
          Send Feedback
        </button>
      </div>

      <CustomModal open={isFeedbackOpen} onClose={toggleFeedback}>
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
            Send Thrive Drive Support a Message
          </h2>
          <FeedbackForm setIsFeedbackOpen={setIsFeedbackOpen} />
        </div>
      </CustomModal>
    </div>
  );
};

export default FeedbackWidget;
