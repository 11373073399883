import { useContext, useState } from "react";
import Button from "@mui/material/Button"; // Material UI Button
import Cookies from "js-cookie";
import axios from "axios";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";
import { UserType } from "../../interfaces";

const typeOptions = [
  { value: "Performance Issue", label: "Performance Issue" },
  { value: "Feature Suggestion", label: "Feature Suggestion" },
  { value: "Misc. Note", label: "Misc. Note" },
];

const FeedbackForm = ({
  setIsFeedbackOpen,
}: {
  setIsFeedbackOpen: (open: boolean) => void;
}) => {
  // Form data state
  const { user }: { user: UserType } = useContext(UserContext);

  const [formData, setFormData] = useState({
    type: "",
    email: user.email || "",
    message: "",
    files: [],
  });

  const token: string | undefined = Cookies.get("token");

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };

  const handleChange = (e: any) => {
    if (e && e.value) {
      // This is for custom components like React Select
      setFormData({
        ...formData,
        type: e.value, // Update the 'type' value specifically
      });
    } else if (e.target) {
      const { name, value, files } = e.target;
      if (name === "files") {
        setFormData({
          ...formData,
          files: Array.from(files), // Store selected files as an array
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const form = new FormData();
    form.append("type", formData.type);
    form.append("email", formData.email);
    form.append("message", formData.message);

    // Append each file to the FormData object
    formData.files.forEach((file) => {
      form.append("files", file);
    });

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/feedback`,
        form,
        axiosConfig
      );
      alert(result.data.message); // Alert success or error message
      setIsFeedbackOpen(false);
    } catch (error) {
      alert("Failed to submit feedback");
    }
  };

  return (
    <div className="p-6">
      <form onSubmit={handleSubmit}>
        <div className="mb-6 flex flex-col items-start">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Your Email Address
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6 flex flex-col items-start">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Type of Feedback
          </label>
          <Select
            options={typeOptions}
            onChange={handleChange}
            placeholder="Select Type"
            classNamePrefix="react-select"
            className="text-gray-700 w-full"
          />
        </div>

        <div className="mb-6 flex flex-col items-start">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Message
          </label>
          <textarea
            name="message"
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
          ></textarea>
        </div>
        <div className="mb-6 flex flex-col items-start">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            OPTIONAL - Upload Screenshots (multiple allowed)
          </label>
          <input
            type="file"
            name="files"
            onChange={handleChange}
            multiple
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>
        <div className="">
          {/* Material UI Submit Button */}
          <Button
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "20px",
              backgroundColor: "#9E1F63FF",
              "&:hover": {
                backgroundColor: "#7A1950FF",
              },
            }}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
